.Div-Routers{
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.color-white{
    color:white;
}

.color-black{
    color:black;
}

a:link{
    text-decoration:none;
}