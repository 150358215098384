.Div-Supports{
    width: 80%;
    margin-top: 70px;
}

.Supports-list{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 10px;
    margin: 10px;
}

.card{
    margin: 5px;
}